import { render, staticRenderFns } from "./momentList.vue?vue&type=template&id=7f50cb35&scoped=true&"
import script from "./momentList.vue?vue&type=script&lang=js&"
export * from "./momentList.vue?vue&type=script&lang=js&"
import style0 from "./momentList.vue?vue&type=style&index=0&id=7f50cb35&prod&scoped=true&lang=scss&rel=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f50cb35",
  null
  
)

export default component.exports